import bindAllMethods from '../../utils/bindAllMethods';
import { getServices } from '../../infra/commonInitializer';
import {
  AuthContextEnum,
  IAuthTokenService
} from '../../services/authTokenService';
import { JWEB_MAX_THRESHOLD_TIME, JWEB_THRESHOLD_TIME } from './constants';
import { getDateDifferenceInSeconds } from './utils/getDateDifferenceInSeconds';

type CriticalScopeHandlerOptions = {
  isNative: boolean;
};
class CriticalScopeHandler {
  private _authTokenService: IAuthTokenService;
  private isNative: boolean;

  constructor(
    options: CriticalScopeHandlerOptions,
    authTokenService: IAuthTokenService
  ) {
    this._authTokenService = authTokenService;
    this.isNative = options.isNative;
    bindAllMethods(this);
  }

  private _getTokenExpirationInfo() {
    // TODO: refactor this when understand how to get the token from the correct context
    let accessToken = this._authTokenService.getToken(AuthContextEnum.tenant);
    if (!accessToken.token) {
      accessToken = this._authTokenService.getToken(AuthContextEnum.tenantless);
    }
    const decodedAccessToken = this._authTokenService.decodeJWT(
      accessToken.token
    );
    return { iat: decodedAccessToken.iat, ca_exp: decodedAccessToken.ca_exp };
  }

  public isCriticalScopesValid(): boolean {
    const tokenData = this._getTokenExpirationInfo();
    const criticalScopesExpirationDate = new Date(tokenData.ca_exp * 1000);
    return criticalScopesExpirationDate.getTime() > Date.now();
  }

  public getLoginCooldown = (): number => {
    if (this.isNative) {
      const tokenData = this._getTokenExpirationInfo();
      const tokenCreationDate = new Date(tokenData.iat * 1000);
      return Math.min(
        JWEB_MAX_THRESHOLD_TIME,
        Math.max(
          0,
          JWEB_THRESHOLD_TIME -
            getDateDifferenceInSeconds(Date.now(), tokenCreationDate.getTime())
        )
      );
    }

    return 0;
  };

  public ensureCriticalScopes = (waitCallback = () => {}): boolean => {
    // Is in Critical scope state.
    if (this.isCriticalScopesValid()) {
      return true;
    }

    const { loginService } = getServices();
    if (!this.isNative) {
      // Web Version
      loginService.redirectToLogin();
      return true;
    }

    // Native Version
    const threshold = this.getLoginCooldown();

    if (threshold <= 0) {
      loginService.redirectToLogin();
      return true;
    } else {
      waitCallback();
      return false;
    }
  };
}

export { CriticalScopeHandlerOptions, CriticalScopeHandler };
