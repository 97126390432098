import bindAllMethods from '../../utils/bindAllMethods';
import { INavigationService } from '../../services/navigationService';
import IApplicationService from '../../services/applicationService/IApplicationService';
import AuthenticationProviderEnum from '../../config/authenticationProviderEnum';
import { LastTenantIdsRepository } from './KeepLatestTenantOnNewTabs/LastTenantIdsRepository';

export type AuthenticateCoptorProviderType = {
  navigationService: INavigationService;
  applicationService: IApplicationService;
  lastTenantIdsRepository: LastTenantIdsRepository;
};

export default class AuthenticateCoptorProvider {
  private _navigationService: INavigationService;
  private _applicationService: IApplicationService;
  private _lastTenantIdsRepository: LastTenantIdsRepository;

  constructor({
    navigationService,
    applicationService,
    lastTenantIdsRepository
  }: AuthenticateCoptorProviderType) {
    this._navigationService = navigationService;
    this._applicationService = applicationService;
    this._lastTenantIdsRepository = lastTenantIdsRepository;
    bindAllMethods(this);
  }

  public init = (): void => {
    if (
      this._applicationService.getAuthenticationProvider() ==
      AuthenticationProviderEnum.coptor
    ) {
      const tenantIdList = this._lastTenantIdsRepository.find();
      if (tenantIdList?.length) {
        const urlSearchParams = new URLSearchParams(window.location.search);

        tenantIdList.forEach((t) => {
          const tenantQueryParam = `t${t.level}`;
          urlSearchParams.set(tenantQueryParam, t.id);
        });

        this._navigationService.replace({
          ...this._navigationService.location,
          search: urlSearchParams.toString()
        });
      }
    }
  };
}
