import { GrantRepository } from '../../services/grants/GrantRepository';
import ShellLocalStorageDAOImpl from '../../dao/ShellLocalStorageDAOImpl';
import { EntitlementRepository } from '../../services/entitlements/EntitlementRepository';
import { ScopeRepository } from '../../services/scope';
import { TenantRepository } from '../../services/tenantHandler';
import { RepositoriesType } from './RepositoriesType';
import { container } from 'tsyringe';
import AuthTokenRepository from '../../services/authTokenService/AuthTokenRepository';
import IdTokenRepository from '../../services/session/IdTokenRepository';
import { PendingGrantRepository } from '../../services/grants/PendingGrantRepository';
import { GrantHistoryRepository } from '../../services/grants/grantsHistory/GrantHistoryRepository';

export default class RepositoryInitializer {
  public static instantiate(): RepositoriesType {
    const shellLocalStorageDAO = container.resolve(ShellLocalStorageDAOImpl);

    const scopeRepository = new ScopeRepository({
      shellDAO: shellLocalStorageDAO
    });

    const authTokenRepository = container.resolve(AuthTokenRepository);

    const tenantRepository = new TenantRepository({
      shellDAO: shellLocalStorageDAO
    });
    const entitlementRepository = new EntitlementRepository({
      shellDAO: shellLocalStorageDAO
    });

    const idTokenRepository = container.resolve(IdTokenRepository);

    const grantRepository = new GrantRepository({
      shellDAO: shellLocalStorageDAO
    });
    const grantHistoryRepository = new GrantHistoryRepository({
      shellDAO: shellLocalStorageDAO
    });

    const pendingGrantRepository = new PendingGrantRepository({
      shellDAO: shellLocalStorageDAO
    });

    const repositories = {
      scopeRepository,
      authTokenRepository,
      tenantRepository,
      entitlementRepository,
      idTokenRepository,
      grantRepository,
      grantHistoryRepository,
      pendingGrantRepository
    };

    return repositories;
  }
}
